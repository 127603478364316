import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const BillingAgreement = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Billing Agreement'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="billing-agreement" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Billing Agreement</h1>
                         </div>
                         <div className="platform-intro-content">
                              <h3>Introduction</h3>
                              <p>
                                   This Billing Agreement is entered into by and between RealProof, Inc., doing business as ListenLayer
                                   ("ListenLayer"), and the individual, business, or entity ("Customer", “you”) that creates a Business Account on
                                   ListenLayer.com and selects a paid plan from our website (Publicly Published Pricing Plan) or enters into a
                                   Contract Plan with us. This Billing Agreement is supplementary to and made a part of the{' '}
                                   <a href="/terms-of-use/">Terms of Service</a> and shares many of the same defined terms (which are capitalized in
                                   this Billing Agreement).
                              </p>
                              <p>
                                   This Billing Agreement provides important details surrounding the costs of our Software and Support Services, the
                                   fees you agree to pay, the commitment you make, our refund policies, cancellation policies, and other important
                                   details. By choosing to enter into any paid plan or agreement with us, you acknowledge having read these terms and
                                   agree to them.
                              </p>
                              <h3>Commitment Period</h3>
                              <p>
                                   Upon selecting a Publicly Published Pricing Plan or entering into a Contract Plan for the use of our Software, you
                                   agree to commit to a predefined period during which you are obligated to pay for the software provided by
                                   ListenLayer (the “Commitment Period”), as outlined below:
                              </p>

                              <p className="no-padding">
                                   <b>Publicly Published Pricing Plan:</b>
                              </p>
                              <ol>
                                   <li>
                                        <b>Monthly Plan:</b> By selecting a monthly pricing plan, you commit to a one (1) month Commitment Period,
                                        during which you agree to pay the specified monthly fee. Some plans may offer a free trial period, within
                                        which you can cancel at any time without incurring charges.
                                   </li>
                                   <li>
                                        <b>Annual Plan:</b> By selecting an annual pricing plan, you commit to a twelve (12) month Commitment Period,
                                        during which you agree to pay the specified annual fee. Annual plans typically include a significant cost
                                        reduction in exchange for the longer-term commitment.
                                   </li>
                              </ol>
                              <p>
                                   <b>Contract Plan:</b> By entering a Contract Plan with us, you commit to a specific period of service indicated in
                                   your Contract Plan, commencing on the service start date, during which you agree to pay the fees as outlined in the
                                   Contract Plan.
                              </p>
                              <p>
                                   The Commitment Period commences on the date of your subscription excluding any trial periods on Publicly Published
                                   Pricing Plans, or the plan start date for Contract Plans, and renews automatically for the same period unless
                                   canceled or modified by either party in accordance with the terms of this Billing Agreement and the Terms of
                                   Service.
                              </p>
                              <p>
                                   To the extent that your selected plan includes a Managed Services fee, such fees follow the same Commitment Period
                                   as the selected software plan.
                              </p>
                              <h3>Cancellation</h3>
                              <p>
                                   You have the right to cancel your subscription with ListenLayer at any time. The process for cancellation varies
                                   depending on the type of plan you have chosen:
                              </p>
                              <p>
                                   <b>Publicly Published Pricing Plan:</b> If you wish to cancel a plan selected from our website, you may do so
                                   directly from within your Business Account settings. It is your responsibility to cancel your account. Simply
                                   submitting a support ticket and expecting our team to close your account will not terminate the account. This is
                                   for the safety and protection of the live data that is being collected, by you from your website.
                              </p>
                              <p>
                                   <b>Contract Plan:</b> To cancel a Contract Plan, you must send an email to{' '}
                                   <a href="mailto:support@listenlayer.com" target="_blank">
                                        support@listenlayer.com
                                   </a>{' '}
                                   with the details of your cancellation request.
                              </p>
                              <p>
                                   Despite cancellation, you are obligated to pay for your Commitment Period as outlined in the Commitment Period
                                   section of this Billing Agreement, although your Business Account may still be paused and deprecated at your
                                   request, or it may remain live until the end of the Commitment Period. Any outstanding Fees, Extra Usage Fees, or
                                   future contracted amounts must be paid in full for cancellation to be effective and applied to your account.
                              </p>
                              <h3>Extra Usage Fees</h3>
                              <p>
                                   Certain plans may involve additional charges for surplus usage (“Extra Usage Fees”) of the Software beyond the
                                   limits stipulated in the plan you selected. This extra usage may be measured based on the count of website users or
                                   the volume of data events, or other methods as specified in the details of your plan. It's notable that many
                                   Contract Plans do not encompass extra usage charges. Please refer to your Contract Plan for details.
                              </p>
                              <p>
                                   Extra Usage Fees are billed at the close of a billing cycle for the extra usage incurred in that cycle. ListenLayer
                                   commits to transparency in billing, and as such, will send you email notifications when your Business Account is
                                   nearing, or has exceeded your plan's usage thresholds. Additionally, a notification will be readily available
                                   within your Business Account on ListenLayer.com if you are nearing or have exceeded your plans allowed usage.
                              </p>
                              <p>
                                   By continuing with a plan that entails Extra Usage Fees, you agree to pay for any additional charges incurred. In
                                   the event of a cancellation of the service, any unbilled Extra Usage Fees will be due and payable by you at the
                                   time of cancellation.
                              </p>
                              <p>
                                   Your adherence to paying these Extra Usage Fees is crucial for maintaining uninterrupted service and access to the
                                   ListenLayer platform.
                              </p>
                              <h3>Subscriptions and Credit Card Charges</h3>
                              <p>
                                   When you select a plan entailing monthly or recurring charges and provide us with your credit card information, you
                                   authorize ListenLayer to automatically charge your credit card for the subscription fees corresponding to your
                                   chosen plan on a recurring basis. The frequency of these charges will correspond to the billing cycle defined by
                                   your selected plan - monthly, annually, or as otherwise specified.
                              </p>
                              <p>For annual plans, any Extra Usage Fees will be billed on a monthly billing schedule to the credit card provided.</p>
                              <p>
                                   If your selected plan includes a trial period, please note that billing will commence automatically at the
                                   conclusion of this trial period, unless you cancel your subscription prior to the trial period's end. It is your
                                   responsibility to cancel your subscription if you do not wish to incur charges.
                              </p>
                              <p>
                                   Your card will be charged on the same date of the month as your initial subscription date for monthly plans, or on
                                   the same date of the year for annual plans, unless your subscription begins or is renewed on a day not contained in
                                   a subsequent month (e.g., your subscription starts on January 30, and the next charge will occur on February 28).
                              </p>
                              <p>
                                   By providing ListenLayer with your credit card information, you affirm that you are authorized to use the
                                   designated card and authorize us to charge your order to that card.
                              </p>
                              <p>
                                   It is your responsibility to keep your credit card details up-to-date to avoid service disruptions. ListenLayer
                                   provides an interface within the Business Account settings on ListenLayer.com for you to update your credit card
                                   information.
                              </p>
                              <p>
                                   In the event that a credit card charge is unable to be processed, ListenLayer will make multiple attempts to charge
                                   the card on file. If after multiple attempts we are unable to process the payment, your account may be suspended
                                   until the outstanding charges are paid.
                              </p>
                              <h3>Contract Plan Billing</h3>
                              <p>
                                   Certain Contract Plans may permit you to receive an invoice for your plan, allowing for payment methods other than
                                   automatic credit card billing. Refer to your Contract Plan for detailed information regarding your payment terms.
                              </p>
                              <p>
                                   We reserve the right to pause your account in the event of non-payment, at any time after a payment becomes
                                   overdue. This pause will remain in effect until the overdue amount is settled in full.
                              </p>
                              <p>
                                   In addition, we reserve the right to impose a late fee for any payments that are not received by the due date as
                                   stipulated in your Contract Plan. A standard late fee of 3% of the overdue amount will be applied monthly (or the
                                   highest amount permissible by law, whichever is lower) until the overdue amount, including any accrued late fees,
                                   is paid in full.
                              </p>
                              <p>
                                   It is crucial to adhere to the payment schedule outlined in your Contract Plan to maintain uninterrupted access to
                                   our Software and Support Services.
                              </p>
                              <h3>Upgrade and Downgrade Policy</h3>
                              <p>You have the flexibility to adjust your plan based on your needs, whether you choose to upgrade or downgrade.</p>
                              <p>
                                   <b>Upgrades:</b> You may upgrade your plan at any time during your Commitment Period. Upon upgrading, any
                                   prepayment made for your previous plan will be credited towards the cost of your newly selected plan. The upgrade
                                   will take effect immediately, and your billing cycle will adjust accordingly to reflect the change. The difference
                                   in cost will be charged to your preferred payment method on file or will be reflected in updated billing for your
                                   Contract Plan.
                              </p>
                              <p>
                                   <b>Downgrades:</b> Downgrading your plan is also an option available to you. Should you choose to downgrade your
                                   plan, the change will take effect at the commencement of your next renewal period following the end of your current
                                   Commitment Period.{' '}
                              </p>
                              <p>
                                   Please note that downgrading your plan during your Commitment Period does not alleviate your obligation to complete
                                   any existing Commitment Period under the terms of the original plan you selected. The downgrade will only apply
                                   once the existing Commitment Period has concluded.
                              </p>
                              <h3>Plan Modifications and Discontinuation</h3>
                              <p>
                                   We reserve the right to discontinue, modify, or change our plans and features offered at any time. These changes
                                   may include discontinuing a plan you are currently subscribed to, altering features within a plan, or changing the
                                   price of plans. Should any such change occur, it may be applied to your plan and account at the close of your
                                   current Commitment Period.
                              </p>
                              <p>
                                   In the event that a plan you are subscribed to is discontinued, we will make every effort to notify you in advance,
                                   providing you with the opportunity to switch to another available plan. If a plan is modified to no longer include
                                   certain features, or if the pricing is changed, these adjustments may also be applied to your plan at the end of
                                   your current Commitment Period.
                              </p>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default BillingAgreement
